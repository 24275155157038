import { Point } from "./point";

export class Circle {
    public radius: number;
    public center: Point;

    constructor(r: number, c: Point) {
        this.radius = r;
        this.center = c;
    }

    surface(): number {
        return Math.PI * this.radius * this.radius;
    }

    distance(circle: Circle): number {
        return this.center.dist(circle.center) - this.radius - circle.radius;
    }
}