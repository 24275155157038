export class Point {
    public x: number;
    public y: number;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    dist(p: Point): number {
        return this.vect(p).norm();
    }

    vect(p: Point): Point {
        return new Point(p.x - this.x, p.y - this.y);
    }

    norm(): number {
        return Math.sqrt(this.x * this.x + this.y * this.y);
    }

    add(v: Point): Point {
        return new Point(this.x + v.x, this.y + v.y);
    }

    mult(a: number): Point {
        return new Point(this.x * a, this.y * a);
    }
}