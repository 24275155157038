import { SyncEvent } from "ts-events";
import { mapRange } from "../utils/math";
import { Force } from "./force";
import { Particle } from "../models/particle";

export type FlickerOpts = {
  flickerEnabled?: boolean;
  minFlickerOpacity?: number,
  maxFlickerOpacity?: number,
  flickerSpeed?: number,
};

export class Flicker extends Force<FlickerOpts> {
  
  private _count: number = 0;

  protected _opts: Required<FlickerOpts> = {
    flickerEnabled: false,
    flickerSpeed: 0,
    minFlickerOpacity: 1.0,
    maxFlickerOpacity: 1.0,
  }

  constructor(opts?: FlickerOpts) {
    super();
    this._opts = { ...this._opts, ...opts };
  }

  public override initialize(nodes: Particle[]) {
    super.initialize(nodes);
  }

  public override apply(alpha: number) {
    if (!this._opts.flickerEnabled) return;
    this._count += 1;
    this._nodes.forEach((p, i) => {
      const val = Math.sin(this._count * this._opts.flickerSpeed + i);
      p.sprite.alpha = mapRange(val, [-1, 1], [this._opts.minFlickerOpacity, this._opts.maxFlickerOpacity]);
    });
  }

  public config = {
    flickerEnabled: {
      type: "checkbox" as const,
      label: "Flicker enabled",
      valueGet: () => this._opts.flickerEnabled,
      inputChanged: (v: boolean) => this.setOptions({ flickerEnabled: v }),
      valueChanged: new SyncEvent<boolean>, 
    },
    minFlickerOpacity: {
      type: "slider" as const,
      label: 'Min Opacity',
      min: 0.1,
      max: 1.0,
      step: 0.01,
      alt: 'Minimum opacity of the particles',
      valueGet: () => this._opts.minFlickerOpacity,
      inputChanged: (v: number) => this.setOptions({ minFlickerOpacity: v }),
      valueChanged: new SyncEvent<number>(),
    },
    maxFlickerOpacity: {
      type: "slider" as const,
      label: 'Max Opacity',
      min: 0.1,
      max: 1.0,
      step: 0.01,
      alt: 'Maximum opacity of the particles',
      valueGet: () => this._opts.maxFlickerOpacity,
      inputChanged: (v: number) => this.setOptions({ maxFlickerOpacity: v }),
      valueChanged: new SyncEvent<number>(),
    },
    flickerSpeed: {
      type: "slider" as const,
      label: 'Flicker Speed',
      min: 0,
      max: 0.5,
      step: 0.01,
      alt: 'Speed of the flickering effect',
      valueGet: () => this._opts.flickerSpeed,
      inputChanged: (v: number) => this.setOptions({ flickerSpeed: v }),
      valueChanged: new SyncEvent<number>(),
    }
  }
}
