import { SimulationNodeDatum } from "d3-force";
import { Victim } from "./victim";
import { Color, Graphics, GraphicsContext, Sprite, Texture } from "pixi.js";
import { clamp, randomPositionInCircle, randomPositionInRect } from "../utils/math";
import { assert } from "../utils/assert";

const targetGr = new GraphicsContext()
    .circle(0, 0, 1)
    .fill(new Color({ r: 95, g: 119, b: 140, a: 1 }));

export type RectBound = {
  x: number;
  y: number;
  width: number;
  height: number;
  type: "rect";
};

export type CircleBound = {
  x: number;
  y: number;
  radius: number;
  type: "circle";
};

export type ParticleOpts = {
  textures: Texture[];
  color: number;
  hoveredColor?: number;
  size: number;
  bounds: RectBound | CircleBound;
  targetSpeed: number;
  centerBias: number;
};

export class Particle implements SimulationNodeDatum {
  private _textures: Texture[] = [];

  private _hovered = false;
  public get hovered() { return this._hovered; }

  private _victim: Victim;
  public get victim() { return this._victim; }
  
  private _sprite: Sprite = new Sprite();
  public get sprite() { return this._sprite; }

  private _target: Graphics = new Graphics(targetGr);
  public get target() { return this._target; }

  public get bounds(): RectBound | CircleBound { return this._opts.bounds; }

  public id: number;
  public x!: number;
  public y!: number;
  public vx!: number;
  public vy!: number;
  public fx: number | null = null;
  public fy: number | null = null;

  protected _opts: Required<ParticleOpts> = {
    textures: [],
    color: 0x000000,
    hoveredColor: 0xff0000,
    size: 10,
    bounds: { x: 0, y: 0, width: 100, height: 100, type: "rect" },
    targetSpeed: 0.1,
    centerBias: 0.1,
  };

  constructor(victim: Victim, opts: Partial<ParticleOpts> = {}) {
    this._opts = { ...this._opts, ...opts };
    this._victim = victim;
    this.id = victim.id;
    this._initParticle();
  }

  public setOptions(opts: Partial<ParticleOpts>) {
    this._opts = { ...this._opts, ...opts };
    if (opts.textures || opts.color || opts.size || opts.hoveredColor) this.updateSprite();
  }

  public toggleHovered(hovered: boolean | undefined) {
    if (hovered === undefined) this._hovered = !this._hovered;
    else this._hovered = hovered;

    this._sprite.tint = this._hovered ? this._opts.hoveredColor : this._opts.color;

    this.fx = this._hovered ? this.x : null;
    this.fy = this._hovered ? this.y : null;
  }

  public updateSprite() {
    assert(this._opts.textures.length > 0, "Textures must be provided");

    this._textures[Math.floor(Math.random() * this._textures.length)];
    const textureIdx = clamp(this._victim.getLevel(), 0, this._opts.textures.length - 1);
    this._sprite.texture = this._opts.textures[textureIdx];
    this._sprite.anchor.set(0.5, 0.5);
    
    this._sprite.tint = this._opts.color;
    this._sprite.width = this._opts.size;
    this._sprite.height = this._opts.size;
  }

  public updatePosition() {
    this._sprite.position.set(this.x, this.y);
  }

  // #region PRIVATE

  private _initParticle() {
    assert(this._opts.bounds, "Starting bounds must be provided");
    [this.x, this.y] = this._randomPositionInBounds(this._opts.bounds);
    this.vx = this.vy = 0;
    this.updateSprite();
  }

  private _randomPositionInBounds(bounds: RectBound | CircleBound): [number, number] {
    if (bounds.type === 'rect') return randomPositionInRect(bounds);
    else return randomPositionInCircle(bounds);
  }

  // #endregion
}
