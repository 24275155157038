import { Container, TextStyle, Text, Graphics, CanvasTextMetrics } from "pixi.js";
import { Victim } from "./victim";
import { CircleBound } from "./particle";

export class Hub {

  public container: Container = new Container();
  public victims: Victim[];
  public x: number = 0;
  public y: number = 0;
  public r: number = 0;
  
  private _title: string;
  private _isUndefined: boolean;
  public get isUndefined() { return this._isUndefined; }

  constructor(title: string, victims: Victim[]) {
    this._title = title;
    this.victims = victims;
    this._isUndefined = title === "undefined";
  }

  public updatePosition(x: number, y: number, r: number) {
    this.x = x;
    this.y = y;
    this.r = r;
    this._createHubSprite(r, `${this._title} (${this.victims.length})`);
    this.container.position.set(x, y);
  }

  public getBounds(): CircleBound | undefined {
    if (this._isUndefined) return undefined;
    else return { x: this.x, y: this.y, radius: this.r, type: "circle" };
  }

  private _createHubSprite(r:number, title: string) {
    const style = new TextStyle({ fontSize: 105, fill: 0x000000, fontFamily: 'RecklessNeue' });
    const text = new Text({ text: title, style });
    const textMetrics: CanvasTextMetrics = CanvasTextMetrics.measureText(title, style);
    const circle = new Graphics()
        .circle(0, 0, r)
        .fill("transparent")
        .stroke({ color: 0x000000, width: 1 });

    text.position.set(0,0);
    text.anchor.set(0.5, 0.5);
    text.scale.set(1.5 * r / textMetrics.width);

    this.container.addChild(circle);
    this.container.addChild(text);
  }
  
}