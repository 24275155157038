import { SyncEvent } from "ts-events";
import { randomRange } from "../utils/math";
import { Force } from "./force";
import { Configuration } from "../config";

export type BrownianMotionOpts = {
  enabled?: boolean;
  maxSpeed?: number;
  brownianFactor?: number;
};

export class BrownianMotion extends Force<BrownianMotionOpts> {

  protected _opts: Required<BrownianMotionOpts> = {
    enabled: false,
    maxSpeed: 0.3,
    brownianFactor: 0
  }

  constructor(opts?: BrownianMotionOpts) {
    super();
    this._opts = { ...this._opts, ...opts };
  }

  public override apply(alpha: number) {
    if (!this._opts.enabled) return;
    this._nodes.forEach((p) => {
      p.vx += randomRange(-this._opts.maxSpeed, this._opts.maxSpeed) * this._opts.brownianFactor;
      p.vy += randomRange(-this._opts.maxSpeed, this._opts.maxSpeed) * this._opts.brownianFactor;
    });
  }

  public config: Configuration<BrownianMotionOpts> = {
    enabled: {
        type: "checkbox" as const,
        label: "Enabled",
        valueGet: () => this._opts.enabled,
        inputChanged: (v: boolean) => this.setOptions({ enabled: v }),
        valueChanged: new SyncEvent<boolean>, 
    },
    maxSpeed: {
      type: "slider" as const,
      label: "maxSpeed",
      valueGet: () => this._opts.maxSpeed,
      min: 0,
      max: 5,
      step: 0.01,
      inputChanged: (v: number) => this.setOptions({ maxSpeed: v }),
      valueChanged: new SyncEvent<number>(),
      alt: "Max speed of the particles",
    },
    brownianFactor: {
      type: "slider" as const,
      label: 'Brownian Factor',
      min: 0,
      max: 1.0,
      step: 0.01,
      valueGet: () => this._opts.brownianFactor,
      inputChanged: (v: number) => this.setOptions({ brownianFactor: v }),
      valueChanged: new SyncEvent<number>(),
      alt: 'How much the particles are affected by brownian motion. Set to 0 to disable.'
    }
  }

}
