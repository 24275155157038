import { csvParse } from "d3-dsv";
import { Victim } from "./victim";
import { minMax } from "../utils/math";

export class Model {

    private _dataUrl: string;
    public victims: Victim[] = [];

    constructor(dataUrl: string) {
        this._dataUrl = dataUrl;
    }

    public async load(): Promise<any> {
        const response = await fetch(this._dataUrl);
        const text = await response.text();
        const data = csvParse(text);
        data.forEach((d: any) => this.victims.push(new Victim(d)));
    }

    public getDistinct(field: keyof Victim): string[] {
        const list = this.victims.map((v: Victim) => (v as any)[field]);
        const set = new Set(list);
        return Array.from(set);
    }

    public getGroups(field: keyof Victim): Record<string, Victim[]> {
        return this.victims.reduce((acc, v: Victim) => {
            const key = (v as any)[field];
            if (!acc[key]) acc[key] = [];
            acc[key].push(v);
            return acc;
        }, {} as Record<string, Victim[]>);
    }

    public getMinMax(field: keyof Victim): [any, any] {
        const list = this.victims.map((v: Victim) => (v as any)[field]);
        return minMax(list);
    }

}