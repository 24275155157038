import { Color, Container, Graphics, GraphicsContext } from "pixi.js";
import { clampToCircle, clampToRect, randomRange } from "../utils/math";
import { Force, Map2DFn } from "./force";
import { SyncEvent } from "ts-events";
import { CircleBound, Particle, RectBound } from "../models/particle";

export type SpringOpts = {
  enabled?: boolean;
  elasticityConstant?: number;
  targetSpeed?: number;
  centerBias?: number;
  showTargets?: boolean;
};

export type SpringParticle = Particle & {
  sTx: number;
  sTy: number;
  sTarget: Graphics;
};

export class Spring extends Force<SpringOpts> {

  protected _nodes: SpringParticle[] = [];

  protected _opts: Required<SpringOpts> = {
    enabled: false,
    elasticityConstant: 0.001,
    targetSpeed: 0.01,
    centerBias: 0.1,
    showTargets: false,
  };

  constructor(opts?: Partial<SpringOpts>) {
    super(opts);
    this._opts = { ...this._opts, ...opts };
  }

  public override initialize(nodes: Particle[]) {
    super.initialize(nodes);

    this._container!.removeChildren();
    this._container!.visible = this._opts.showTargets;

    this._initTargets();
  }

  public override resize(width: number, height: number, container: Container) {
    super.resize(width, height, container);
    this._container!.visible = this._opts.showTargets;
  }

  public override draw(worldToScreen: Map2DFn): void {
    this._nodes.forEach((p) => {
      const { x, y } = worldToScreen(p.sTx, p.sTy);
      p.sTarget.position.set(x, y);
    });
  }

  private _initTargets() {
    const targetGr = new GraphicsContext()
      .circle(0, 0, 1)
      .fill(new Color({ r: 95, g: 119, b: 140, a: 1 }));

    this._nodes.forEach((p) => {
      let target = new Graphics(targetGr);

      p.sTx = p.x;
      p.sTy = p.y;
      p.sTarget = target;

      this._container!.addChild(target);
      this._updateTargetPosition(p);
    });
  }

  public override apply() {
    if (!this._opts.enabled) return;
    this._nodes.forEach((p) => {
      this._updateTargetPosition(p);
      p.vx += (p.sTx - p.x) * this._opts.elasticityConstant;
      p.vy += (p.sTy - p.y) * this._opts.elasticityConstant;
    });
  }
  
  private _updateTargetPosition(p: SpringParticle) {
    if (p.bounds.type === 'circle') this._updateTargetPositionCircle(p);
    else this._updateTargetPositionRect(p);
  }
  
  private _updateTargetPositionRect(p: SpringParticle) {
    const bounds = p.bounds as RectBound;
    p.sTx += randomRange(-bounds.width * this._opts.targetSpeed, bounds.width * this._opts.targetSpeed);
    p.sTy += randomRange(-bounds.height * this._opts.targetSpeed, bounds.height * this._opts.targetSpeed);
    [p.sTx, p.sTy] = clampToRect(p.sTx, p.sTy, bounds);
  }

  private _updateTargetPositionCircle(p: SpringParticle) {
    const bounds = p.bounds as CircleBound;   
    const diameter = bounds.radius * 2;
    p.sTx += randomRange(-diameter * this._opts.targetSpeed, diameter * this._opts.targetSpeed);
    p.sTy += randomRange(-diameter * this._opts.targetSpeed, diameter * this._opts.targetSpeed);
    // bias towards the center
    p.sTx += (bounds.x - p.sTx) * (this._opts.centerBias * this._opts.targetSpeed);
    p.sTy += (bounds.y - p.sTy) * (this._opts.centerBias * this._opts.targetSpeed);
    [p.sTx, p.sTy] = clampToCircle(p.sTx, p.sTy, bounds);
  }

  public config = {
    enabled: {
      type: "checkbox" as const,
      label: "Enabled",
      valueGet: () => this._opts.enabled,
      inputChanged: (v: boolean) => this.setOptions({ enabled: v }),
      valueChanged: new SyncEvent<boolean>, 
    },
    elasticityConstant: {
      type: "slider" as const,
      label: 'Elasticity constant',
      min: 0,
      max: 0.01,
      step: 0.0001,
      alt: 'Elasticity constant of the bounds force',
      valueGet: () => this._opts.elasticityConstant,
      inputChanged: (v: number) => this.setOptions({ elasticityConstant: v }),
      valueChanged: new SyncEvent<number>(),
    },
    centerBias: {
      type: "slider" as const,
      label: 'Center Bias',
      min: 0,
      max: 1,
      step: 0.001,
      alt: 'Bias towards the center of the bounds',
      valueGet: () => this._opts.centerBias,
      inputChanged: (v: number) => this.setOptions({ centerBias: v }),
      valueChanged: new SyncEvent<number>(),
    },
    targetSpeed: {
      type: "slider" as const,
      label: 'Target Speed',
      min: 0,
      max: 0.1,
      step: 0.001,
      alt: 'Speed of the target',
      valueGet: () => this._opts.targetSpeed,
      inputChanged: (v: number) => this.setOptions({ targetSpeed: v }),
      valueChanged: new SyncEvent<number>(),
    },
    showTargets:{
      type: 'checkbox' as const,
      label: 'Show Targets',
      valueGet: () => this._opts.showTargets,
      inputChanged: (v: boolean) => this.setOptions({ showTargets: v }),
      valueSet: (v: boolean) => this._container!.visible = v,
      valueChanged: new SyncEvent<boolean>(),
      alt: 'Show or hide the targets'
    }
  }

}

