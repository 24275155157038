import { Circle } from "./circle";
import { Point } from "./point";

export class Packer {
  public w: number = 0;
  public h: number = 0;
  public circles: Circle[];
  public bounds: Circle[] = [];
  
  private _radiuses: number[];
  private _ratio: number;
  private _tmpBounds: Circle[] = [];

  constructor(radiuses: number[], ratio?: number) {
    this._radiuses = radiuses;
    this._ratio = ratio || 1;
    this.circles = this.solve();
  }

  // check if a circle is inside our rectangle
  private _isWithinBounds(radius: number, center: Point): boolean {
    if (center.x - radius < -this.w / 2) return false;
    if (center.x + radius > this.w / 2) return false;
    if (center.y - radius < -this.h / 2) return false;
    if (center.y + radius > this.h / 2) return false;
    return true;
  }

  // approximate a segment with an "infinite" radius circle
  private _getBoundingCircle(x0: number, y0: number, x1: number, y1: number, bounding_r: number): Circle {
    const xm = Math.abs((x1 - x0) * this.w);
    const ym = Math.abs((y1 - y0) * this.h);
    const m = xm > ym ? xm : ym;
    const theta = Math.asin(m / 4 / bounding_r);
    const r = bounding_r * Math.cos(theta);
    return new Circle(
      bounding_r,
      new Point(
        (r * (y0 - y1)) / 2 + ((x0 + x1) * this.w) / 4,
        (r * (x1 - x0)) / 2 + ((y0 + y1) * this.h) / 4
      )
    );
  }

  // return the corner placements for two circles
  corner(radius: number, c1: Circle, c2: Circle): Circle[] {
    let u = c1.center.vect(c2.center); // c1 to c2 vector
    const A = u.norm();
    if (A == 0) return []; // same centers
    u = u.mult(1 / A); // c1 to c2 unary vector

    // compute c1 and c2 intersection coordinates in (u,v) base
    const B = c1.radius + radius;
    const C = c2.radius + radius;
    if (A > B + C) return []; // too far apart
    const x = (A + (B * B - C * C) / A) / 2;
    const y = Math.sqrt(B * B - x * x);
    const base = c1.center.add(u.mult(x));

    const res: Circle[] = [];
    const p1 = new Point(base.x - u.y * y, base.y + u.x * y);
    const p2 = new Point(base.x + u.y * y, base.y - u.x * y);
    if (this._isWithinBounds(radius, p1)) res.push(new Circle(radius, p1));
    if (this._isWithinBounds(radius, p2)) res.push(new Circle(radius, p2));
    return res;
  }

  // try to fit all circles into a rectangle of a given surface
  private _findOptimalPlacements(surface: number): Circle[] {

    // deduce starting dimensions from surface
    const bounding_r = Math.sqrt(surface) * 100; // "infinite" radius
    this.w = Math.sqrt(surface * this._ratio);
    this.h = this.w / this._ratio;

    // place our bounding circles
    const placed = [
      this._getBoundingCircle(1, 1, 1, -1, bounding_r),
      this._getBoundingCircle(1, -1, -1, -1, bounding_r),
      this._getBoundingCircle(-1, -1, -1, 1, bounding_r),
      this._getBoundingCircle(-1, 1, 1, 1, bounding_r),
    ];

    // Initialize our rectangles list
    const unplaced = this._radiuses.slice(0); // clones the array
    while (unplaced.length > 0) {
      // compute all possible placements of the unplaced circles
      const lambda: { [key: number]: number } = {};
      const circle: { [key: number]: Circle } = {};
      for (let i = 0; i != unplaced.length; i++) {
        let lambda_min = 1e10;
        lambda[i] = -1e10;
        // match current circle against all possible pairs of placed circles
        for (let j = 0; j < placed.length; j++)
          for (let k = j + 1; k < placed.length; k++) {
            // find corner placement
            const corners = this.corner(unplaced[i], placed[j], placed[k]);

            // check each placement
            for (let c = 0; c != corners.length; c++) {
              // check for overlap and compute min distance
              let d_min = 1e10;
              for (var l = 0; l != placed.length; l++) {
                // skip the two circles used for the placement
                if (l == j || l == k) continue;

                // compute distance from current circle
                const d = placed[l].distance(corners[c]);
                if (d < 0) break; // circles overlap

                if (d < d_min) d_min = d;
              }
              if (l == placed.length) {
                // no overlap
                if (d_min < lambda_min) {
                  lambda_min = d_min;
                  lambda[i] = 1 - d_min / unplaced[i];
                  circle[i] = corners[c];
                }
              }
            }
          }
      }

      // select the circle with maximal gain
      let lambda_max = -1e10;
      let i_max = -1;
      for (let i = 0; i != unplaced.length; i++) {
        if (lambda[i] > lambda_max) {
          lambda_max = lambda[i];
          i_max = i;
        }
      }

      // failure if no circle fits
      if (i_max == -1) break;

      // place the selected circle
      unplaced.splice(i_max, 1);
      placed.push(circle[i_max]);
    }

    // return all placed circles except the four bounding circles
    this._tmpBounds = placed.splice(0, 4);
    return placed;
  }

  // find the smallest rectangle to fit all circles
  public solve(precision = 1000): Circle[] {
    // compute total surface of the circles
    let surface = 0;
    this._radiuses.forEach(r => surface += Math.PI * r * r);

    // set a suitable precision
    const limit = surface / precision;

    let step = surface / 2;
    let res: Circle[] = [];
    while (step > limit) {
      const placement = this._findOptimalPlacements(surface);
      // console.log(`placed ${placement.length} out of ${this.radiuses.length} for surface ${surface}`)
      if (placement.length != this._radiuses.length) {
        surface += step;
      } else {
        res = placement;
        this.bounds = this._tmpBounds;
        surface -= step;
      }
      step /= 2;
    }
    return res;
  }
}