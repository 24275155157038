import { Packer } from "../circlepacker/packer";


export type Circle = { x: number, y: number, radius: number };

export type Rect = {x: number, y:number, width:number, height:number }


/*
* Random number between min and max
* @param {number} min
* @param {number} max
* @returns {number} random number between min and max (inclusive)
*/
export function randomRange(min:number, max:number, rng = Math.random) {
    return rng() * (max - min) + min;
}

/*
* Random number with a gaussian distribution
* @param {number} mean
* @param {number} stdev
* @returns {number} random number with a gaussian distribution
*/
export function gaussianRandom(mean=0, stdev=1, rng = Math.random) {
    const u = 1 - rng(); // Converting [0,1) to (0,1]
    const v = rng();
    const z = Math.sqrt( -2.0 * Math.log( u ) ) * Math.cos( 2.0 * Math.PI * v );
    return z * stdev + mean;
}

export function randomNormals(rng = Math.random): [number, number]{
  const u1 = 1 - rng(); //Convert [0,1) to (0,1)
  const u2 = 1 - rng();
  const R = Math.sqrt(-2.0 * Math.log(u1));
  const Θ = 2.0 * Math.PI * u2;
  return [R * Math.cos(Θ), R * Math.sin(Θ)];
};

/*
* Random number with a skew normal distribution
* The ξ, ω, and α refer to the location (mean), scale (standard deviation), and shape (skewness). You can see that when skewness is 0, the return value is simply the normal variate transformed in a standard way to the given mean with the given standard deviation.
* @param {number} ξ - location (mean).
* @param {number} ω - scale (standard deviation)
* @param {number} α - shape (skewness)
* @returns {number} random number with a skew normal distribution
* @see https://spin.atomicobject.com/skew-normal-prng-javascript/
*/
export function randomSkewNormal(mean: number, stdev: number, skew = 0, rng = Math.random) {
  const [u0, v] = randomNormals(rng);
  if (skew === 0) return mean + stdev * u0;
  const 𝛿 = skew / Math.sqrt(1 + skew * skew);
  const u1 = 𝛿 * u0 + Math.sqrt(1 - 𝛿 * 𝛿) * v;
  const z = u0 >= 0 ? u1 : -u1;
  return mean + stdev * z;
};

export function mapRange(value:number, domain: [number, number], range: [number, number]) {
    return (value - domain[0]) / (domain[1] - domain[0]) * (range[1] - range[0]) + range[0];
}

export function clampToCircle(x: number, y: number, { x: cx, y: cy, radius: cr} : Circle) {
  const dx = x - cx;
  const dy = y - cy;
  const dist = Math.sqrt(dx * dx + dy * dy);
  if (dist > cr) return [cx + dx / dist * cr, cy + dy / dist * cr];
  return [x, y];
}

export function clampToRect(x: number, y: number, { x: rx, y: ry, width: rw, height: rh }: Rect) {
  return [Math.max(rx, Math.min(x, rx + rw)), Math.max(ry, Math.min(y, ry + rh))];
}

export function distributePointsInRect(radiuses: number[], width: number, height: number): Array<[number, number, number]> {
  if (radiuses.length === 0) return [];
  if (radiuses.length === 1) return [[width / 2, height / 2, Math.min(width, height) / 2]];
  const packer = new Packer(radiuses, width/height);
  const dx = packer.w! / 2;
  const dy = packer.h! / 2;
  const zx = width / packer.w!;
  const zy = height / packer.h!;
  const zoom = zx < zy ? zx : zy;
  return packer.circles.map(c => [
      (c.center.x + dx) * zx,
      (c.center.y + dy) * zy,
      c.radius * zoom
  ]);
}

export function getClosestLowerNumber(num: number, candidates: number[]): number {
  return candidates.filter(n => n < num).sort((a, b) => b - a)[0];
}

export function minMax(list: any[]): [any, any] {
  return [list.reduce((a, b) => a < b ? a : b), list.reduce((a, b) => a > b ? a : b)];
}

export function clamp(v: number, min: number, max: number) {
  return Math.min(max, Math.max(min, v));
}

export function randomPositionInRect(bouds: Rect): [number, number] {
  return [randomRange(bouds.x, bouds.x + bouds.width), randomRange(bouds.y, bouds.y + bouds.height)];
}

export function randomPositionInCircle(bounds: Circle): [number, number] {
  const r = Math.sqrt(Math.random()) * bounds.radius;
  const theta = Math.random() * 2 * Math.PI;
  return [bounds.x + r * Math.cos(theta), bounds.y + r * Math.sin(theta)];
}

export function lerp(a: number, b: number, t: number) {
  return a + (b - a) * t;
}

export function isClose(a: number, b: number, epsilon = 1e-6) {
  return Math.abs(a - b) < epsilon;
}
