import { Force as D3Force } from "d3-force";
import { Container } from "pixi.js";
import { Configurable, Options } from "../config";
import { Particle } from "../models/particle";

export type Map2DFn = (x: number, y: number) => {x: number, y: number};

export abstract class Force<Opts extends Options> extends Configurable<Opts> {

    protected _nodes: Particle[] = [];
    protected _width: number = 0;
    protected _height: number = 0;
    protected _container?: Container;

    public force: D3Force<Particle, any>;

    constructor(opts?: Partial<Opts>) {
        super();
        this._opts = { ...this._opts, ...opts };
        this.force = this.apply.bind(this);
        this.force.initialize = this.initialize.bind(this);
    }

    // @ts-ignore-next-line
    public draw(worldToScreen: Map2DFn, zoomFactor: number): void {}

    public initialize(nodes: Particle[]): void {
        this._nodes = nodes;
    }

    public resize(width: number, height: number, container: Container): void {
        this._width = width;
        this._height = height;
        this._container = container;
    }
    
    abstract apply(alpha: number): void;

}