import { distanceInKm } from "../utils/geo";

export type VictimData = {
    id: string,
    cognome: string,
    nome: string,
    grado: string,
    corpo: string,
    comune_n: string,
    provincia_n: string,
    regione_n: string,
    data_n_inizio: string,
    data_n_fine: string,
    lat_n: string,
    lng_n: string,
    bounds_n_n: string,
    bounds_s_n: string,
    bounds_e_n: string,
    bounds_w_n: string,
    comune_m: string,
    provincia_m: string,
    regione_m: string,
    data_m_inizio: string,
    data_m_fine: string,
    causa_m: string,
    lat_m: string,
    lng_m: string,
    bounds_n_m: string,
    bounds_s_m: string,
    bounds_e_m: string,
    bounds_w_m: string,
    siti: string,
    mansione: string,
    biografia: string,
    evento: string,
    fonti: string,
};

export class Victim {

    public id: number;
    public cognome?: string;
    public nome?: string;
    public grado?: string;
    public corpo?: string;
    public comune_n?: string;
    public provincia_n?: string;
    public regione_n?: string;
    public data_n_inizio?: Date;
    public data_n_fine?: Date;
    public lat_n?: number;
    public lng_n?: number;
    public radius_km_n?: number;

    public comune_m?: string;
    public provincia_m?: string;
    public regione_m?: string;
    public data_m_inizio?: Date;
    public data_m_fine?: Date;
    public causa_m?: string;
    public lat_m?: number;
    public lng_m?: number;
    public radius_km_m?: number;
    public siti?: string;
    public mansione?: string;
    public biografia?: string;
    public evento?: string;
    public fonti?: string;

    constructor(data: VictimData) {
        this.id = +data.id;
        this.cognome = this._parseString(data.cognome);
        this.nome = this._parseString(data.nome);
        
        this.corpo = this._parseString(data.corpo);
        this.grado = this._parseString(data.grado);
        this.mansione = data.mansione;

        this.comune_n = this._parseString(data.comune_n);
        this.provincia_n = this._parseString(data.provincia_n);
        this.regione_n = this._parseString(data.regione_n);
        this.data_n_inizio = this._parseDate(data.data_n_inizio, "start");
        this.data_n_fine = this._parseDate(data.data_n_fine, "end");

        this.comune_m = this._parseString(data.comune_m);
        this.provincia_m = this._parseString(data.provincia_m);
        this.regione_m = this._parseString(data.regione_m);
        this.data_m_inizio = this._parseDate(data.data_m_inizio, "start");
        this.data_m_fine = this._parseDate(data.data_m_fine, "end");
        this.causa_m = this._parseString(data.causa_m);

        this.lng_m = this._parseNumber(data.lng_m);
        this.lat_m = this._parseNumber(data.lat_m);
        if (this.lng_m && this.lat_m && +data.bounds_e_m)
            this.radius_km_m = distanceInKm(this.lng_m, this.lat_m, +data.bounds_e_m, this.lat_m);

        this.biografia = this._parseString(data.biografia);
        this.evento = this._parseString(data.evento);
        this.fonti = this._parseString(data.fonti);
        this.siti = this._parseString(data.siti);
    }

    public getLevel(): number {
        return Math.floor(Math.random() * 5);
    }

    private _parseDate(date: string, type: "start" | "end"): Date | undefined {
        if (!date) return undefined;
        const start = type === "start";
        const res = date.match(/(?<d>\d{1,2})\/(?<m>\d{1,2})\/(?<y>\d{4})/);
        const { d, m, y } = res?.groups || {};
        if (!y) {
            console.warn(`Invalid date: ${date}`);
            return undefined;
        }

        const day = d ? +d : start ? 1 : 31;
        const month = m ? +m : start ? 1 : 12;
        const year = +y;
        return new Date(year, month - 1, day);
    }

    private _parseString(str: string): string|undefined {
        str = str.trim();
        return str.length > 0 ? str : undefined;
    }

    private _parseNumber(num: string): number|undefined {
        const n = +num;
        return isNaN(n) ? undefined : n;
    }

}