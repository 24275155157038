import { Application, Assets, Container, Texture, Rectangle, FederatedPointerEvent } from 'pixi.js';
import { Simulation, forceSimulation } from 'd3-force';
import { SyncEvent } from 'ts-events';
import seedrandom from 'seedrandom';

import { Force } from './forces/force';

import { Configurable, Configuration } from './config';
import { Particle } from './models/particle';
import { Model } from './models/model';
import { Hub } from './models/hub';
import { Victim } from './models/victim';

import { clamp, distributePointsInRect, gaussianRandom, isClose, lerp } from './utils/math';
import { hexToStr } from './utils/color';

type AppOptions = {
    numParticles: number,
    searchText: string,
    damp: number,
    backgroundColor: number,
    hoveredParticleColor: number,
    size: number,
    sizeVariability: number,
    particleColor: number,
    mouseRadius: number,
    spriteSetIdx: number,
    aggregationFieldIdx: number,
    zoomSpeed: number,
    zoomInertia: number,
    fonts?: FontConfig[],
    spriteSets?: SpriteSetConfig[],
};

export type FontConfig = {
    alias: string,
    src: string,
}

export type SpriteSetConfig = {
    label: string,
    set: string[],
}

export class App extends Configurable<AppOptions>{

    private _width = window.innerWidth;
    private _height = window.innerHeight;

    private _pixi = new Application();
    private _particles: Particle[] = [];
    private _hoveredParticle?: Particle;
    private _particlesContainer = new Container();
    private _targetsContainer = new Container();
    private _boundsContainer = new Container();
    private _particleTextures: Texture[] = [];
    private _canvas: HTMLCanvasElement;

    private _hubs: Record<string, Hub> = {};
    private _hubsContainer = new Container();

    private _simulation?: Simulation<Particle, undefined>;
    private _forces: Map<string, { force: Force<any>, container: Container}> = new Map();

    private _dragging = false;
    private _dragStart = {x: 0, y: 0};
    private _viewportPos = {x: 0, y: 0, zoom: 1};
    private _targetViewportPos = {x: 0, y: 0, zoom: 1};
    private _minZoom: number = 1;
    private _maxZoom: number = 10;
    private _model: Model;

    private _aggregationFields: (keyof Victim | null)[] = [null, "corpo", "regione_n", "regione_m"];

    public constructor(model: Model, canvas: HTMLCanvasElement, opts: Partial<AppOptions> = {}) {
        super();
        this._opts = { ...this._opts, ...opts };
        this._canvas = canvas;
        this._model = model;
    }

    public setInteractivety(enabled: boolean) {
        this._canvas.style.pointerEvents = enabled ? 'auto' : 'none';
    }

    public async init() {
        await this._pixi.init({
            width: this._width,
            height: this._height,
            resizeTo: window,
            backgroundAlpha: 0,
            resolution: window.devicePixelRatio || 1,
            autoDensity: true,
            canvas: this._canvas,
        });
        document.body.style.backgroundColor = hexToStr(this._opts.backgroundColor);

        const fontUrls = this._opts.fonts?.map(f => ({ alias: f.alias, src: f.src }));
        Assets.addBundle('fonts', fontUrls);
        await Assets.loadBundle('fonts');

        this._pixi.stage.addChild(this._particlesContainer);
        this._pixi.stage.addChild(this._targetsContainer);
        this._pixi.stage.addChild(this._boundsContainer);
        this._pixi.stage.addChild(this._hubsContainer);

        this._pixi.stage.interactive = true;
        this._pixi.stage.hitArea = new Rectangle(0, 0, this._width, this._height);
        this._pixi.stage.interactiveChildren = false;

        this._pixi.stage
            .on('pointermove', e => this._onMouseMove(e))
            .on('pointerdown', e => this._startDrag(e))
            .on('click', () => this._onClick())
            .on('pointerup', () => this._stopDrag())
            .on('pointerupoutside', () => this._stopDrag())
            .on('wheel', e => this._onWheel(e));
        
        window.addEventListener('resize', () => this._onResize());
        
        await this._initParticleTextures(this._opts.spriteSets[this._opts.spriteSetIdx].set);
        this._initParticles();

        this._pixi.ticker.add(() => this._onTick());
    }

    // #region HANDLERS
    
    private _onMouseMove(e: FederatedPointerEvent) {
        const {x, y} = e.global
        if (this._dragging) this._handleDrag(x, y);
        else this._updateHoveredParticle(x, y);
    }

    private _onClick() {
        if (!this._hoveredParticle) return;
        console.table(this._hoveredParticle.victim);
    }

    private _onResize() {
        this._width = window.innerWidth;
        this._height = window.innerHeight;
        this._pixi.resize();
        this._updateForces();
        this._updateHubs();
    }
    
    private _onTick() {
        this._simulation?.tick();
        this.getForces().forEach(([_, force]) => force.draw(this._worldToScreen.bind(this), this._viewportPos.zoom));
        this._drawParticles();
        this._drawHubs();
        this._updateZoomPan();
    }

    // #endregion

    // #region PAN AND ZOOM

    private _startDrag(e: FederatedPointerEvent) {
        this._dragging = true;
        this._dragStart = {x: e.globalX, y: e.globalY};
    }

    private _stopDrag() {
        this._dragging = false;
    }

    private _onWheel(e: WheelEvent) {
        const x = e.clientX;
        const y = e.clientY;
        
        // dx and dy are the coordinates of the mouse in the world space
        const { x: dx, y: dy } = this._screenToWorld(x, y);
    
        // delta is the zoom factor
        const delta = e.deltaY > 0 ? 1 - this._opts.zoomSpeed : 1 + this._opts.zoomSpeed;
        this._targetViewportPos.zoom = clamp(this._viewportPos.zoom * delta, this._minZoom, this._maxZoom);
        
        // update the pan offset to keep the mouse in the same position
        this._targetViewportPos.x = x - dx * this._targetViewportPos.zoom;
        this._targetViewportPos.y = y - dy * this._targetViewportPos.zoom;
    
        // clamp the target position to keep the view inside the bounds
        this._clampTargetPosition();
    }

    public setZoom(zoom: number, instant = false, constrain = true) {
        zoom = constrain ? clamp(zoom, this._minZoom, this._maxZoom) : zoom;
        this._targetViewportPos.zoom = zoom;
        if (instant) this._viewportPos.zoom = this._targetViewportPos.zoom;
        this._clampTargetPosition();
    }

    public setPan(x: number, y: number, instant = false, constrain = true) {
        this._targetViewportPos.x = x;
        this._targetViewportPos.y = y;
        if (instant) {
            this._viewportPos.x = this._targetViewportPos.x;
            this._viewportPos.y = this._targetViewportPos.y;
        }
        this._clampTargetPosition();
    }

    private _updateZoomPan() {
        if (this._viewportPos.zoom === this._targetViewportPos.zoom) return;
        // is zoomfactor is close to the target, snap to the target
        if (isClose(this._viewportPos.zoom, this._targetViewportPos.zoom)) this._snapToTarget();
        this._viewportPos.zoom = lerp(this._viewportPos.zoom, this._targetViewportPos.zoom, this._opts.zoomInertia);
        this._viewportPos.x = lerp(this._viewportPos.x, this._targetViewportPos.x, this._opts.zoomInertia);
        this._viewportPos.y = lerp(this._viewportPos.y, this._targetViewportPos.y, this._opts.zoomInertia);
    }

    private _snapToTarget() {
        this._viewportPos.zoom = this._targetViewportPos.zoom;
        this._viewportPos.x = this._targetViewportPos.x;
        this._viewportPos.y = this._targetViewportPos.y;
    }

    private _handleDrag(x: number, y: number) {
        // dx and dy represent the distance moved by the mouse
        const dx = x - this._dragStart.x;
        const dy = y - this._dragStart.y;

        // update the pan offset
        this._targetViewportPos.x += dx;
        this._targetViewportPos.y += dy;
        this._clampTargetPosition();

        // update the drag start position
        this._dragStart = {x: x, y: y};
    }

    private _clampTargetPosition() {
        this._targetViewportPos.x = Math.min(this._targetViewportPos.x, 0);
        this._targetViewportPos.y = Math.min(this._targetViewportPos.y, 0);
        this._targetViewportPos.x = Math.max(this._targetViewportPos.x, this._width - this._width * this._targetViewportPos.zoom);
        this._targetViewportPos.y = Math.max(this._targetViewportPos.y, this._height - this._height * this._targetViewportPos.zoom);
    }

    private _worldToScreen(x: number, y: number) {
        return {
            x: x * this._viewportPos.zoom + this._viewportPos.x,
            y: y * this._viewportPos.zoom + this._viewportPos.y
        };
    }

    private _screenToWorld(x: number, y: number) {
        return {
            x: (x - this._viewportPos.x) / this._viewportPos.zoom,
            y: (y - this._viewportPos.y) / this._viewportPos.zoom
        };
    }

    // #endregion

    // #region PARTICLES

    private _initParticles() {
        this._particlesContainer.removeChildren();
        this._particles = [];

        const victims = this._model.victims.slice(0, this._opts.numParticles);
        victims.forEach(v => {
            const p = new Particle(v, {
                bounds: this._getAppBounds(),
                centerBias: 0.1,
                targetSpeed: 0.001,
                textures: this._particleTextures,
            });

            this._particles.push(p);
            this._particlesContainer.addChild(p.sprite);
        })

        this._updateParticles();
        this._updateSimulation();
    }

    private _updateParticles() {
        const rnd = seedrandom('size');
        this._particles.forEach(p => {
            const size = gaussianRandom(this._opts.size, this._opts.sizeVariability, rnd);
            p.setOptions({
                size: size,
                color: this._opts.particleColor,
                hoveredColor: this._opts.hoveredParticleColor,
                textures: this._particleTextures,
            });
        });
    }

    private _drawParticles() {
        this._particles.forEach(p => {
            const { x, y } = this._worldToScreen(p.x, p.y);
            p.sprite.position.set(x, y);
        });
    }

    private _updateHoveredParticle(x: number, y: number) {
        if (this._hoveredParticle) this._hoveredParticle.toggleHovered(false);
        let { x: wx, y: wy } = this._screenToWorld(x, y);
        this._hoveredParticle = this._simulation?.find(wx, wy, this._opts.mouseRadius / this._viewportPos.zoom);
        if (this._hoveredParticle) this._hoveredParticle.toggleHovered(true);
    }
    
    private _filterParticles() {
        const v = this._opts.searchText.length ? 1 / (this._opts.searchText.length * 20) : 1;
        const rnd = seedrandom('test');
        this._particles.forEach(p => p.sprite.visible = rnd() < v);
    }

    private async _initParticleTextures(urls: string[]) {
        const promises = urls.map(url => Assets.load<Texture>(url));
        this._particleTextures = await Promise.all(promises);
    }

    // #endregion

    // #region SIMULATION

    private _updateSimulation() {
        this._simulation = forceSimulation<Particle>(this._particles)
            .alphaDecay(0)
            .velocityDecay(this._opts.damp)
            .stop();

        this.getForces().forEach(([name, force]) => {
            this._simulation?.force(name, force.force);
        });
    }

    private _updateForces() {
        const forces = Array.from(this._forces).map(([_, force]) => force);
        forces.forEach(f => f.force.resize(this._width, this._height, f.container));
    }

    public addForce(name: string, force: Force<any>) {
        const container = new Container();
        this._forces.set(name, {force, container});
        force.resize(this._width, this._height, container);
        this._simulation?.force(name, force.force);
        this._pixi.stage.addChildAt(container, 0);
    }

    public removeForce(name: string) {
        this._forces.get(name)?.container.destroy();
        this._forces.delete(name);
        this._simulation?.force(name, null);
    }

    public getForces(): Array<[string, Force<any>]> {
        return Array.from(this._forces.entries(), ([name, {force}]) => [name, force]);
    }

    // #endregion

    // #region HUBS

    private _updateHubs() {
        this._hubs = {};
        this._hubsContainer.removeChildren();

        const field = this._aggregationFields[this._opts.aggregationFieldIdx];
        if (!field) return;

        const groups = Object.entries(this._model.getGroups(field))
        groups.forEach(([key, victims]) => this._hubs[key] = new Hub(key, victims));

        // sort them to keep the corresponding circles in the same order
        const visibleHubs = Object.values(this._hubs).filter(h => !h.isUndefined).sort((a, b) => b.victims.length - a.victims.length);
        const radiuses = visibleHubs.map(h => Math.sqrt(h.victims.length * 10));
        const hubCircles = distributePointsInRect(radiuses, this._width, this._height).sort((a, b) => b[2] - a[2]);
        visibleHubs.forEach((hub, i) => {
            hub.updatePosition(...hubCircles[i])
            this._hubsContainer.addChild(hub.container);
        });

        this._particles.forEach(p => {
            const hub = this._hubs[(p.victim[field] ?? 'undefined')!.toString()];
            const bounds = hub?.getBounds();
            if (bounds) p.setOptions({ bounds: hub.getBounds()});
            else p.setOptions({ bounds: this._getAppBounds() });
        });

        this._updateForces();
        this._drawHubs();
    }

    private _drawHubs() {
        Object.values(this._hubs).forEach((hub) => {
            const { x, y } = this._worldToScreen(hub.x, hub.y);
            hub.container.position.set(x, y);
            hub.container.scale.set(this._viewportPos.zoom);
        });
    }

    private _getAppBounds() {
        return {
            x: 0,
            y: 0,
            width: this._width,
            height: this._height,
            type: 'rect' as const,
        };
    }

    // #endregion

    // #region CONFIG
    
    protected _opts: Required<AppOptions> = {
        numParticles: 1000,
        searchText: '',
        damp: 0.1,
        backgroundColor: 0xB0CAE1,
        hoveredParticleColor: 0xFF0000,
        sizeVariability: 0,
        particleColor: 0xffffff,
        size: 8,
        mouseRadius: 50,
        spriteSetIdx: 0,
        aggregationFieldIdx: 0,
        zoomSpeed: 0.5,
        zoomInertia: 0.1,
        fonts: [],
        spriteSets: [],
    };

    public config: Configuration<AppOptions> = {
        searchText: {
            type: "text" as const,
            label: "Search Text",
            valueGet: () => this._opts.searchText,
            inputChanged: (v: string) => this.setOptions({ searchText: v }),
            valueSet: () => this._filterParticles(),
            valueChanged: new SyncEvent<string>(),
            alt: "Search text to filter particles",
        },
        numParticles: {
            type: "slider" as const,
            label: "Number of Particles",
            valueGet: () => this._opts.numParticles,
            min: 1,
            max: 5000,
            step: 10,
            inputChanged: (v: number) => this.setOptions({ numParticles: v }),
            valueChanged: new SyncEvent<number>(),
            valueSet: () => this._initParticles(),
            alt: "Number of particles to display",
        },
        size: {
          type: "slider" as const,
          label: 'Particle Size',
          min: 5,
          max: 50,
          step: 1,
          alt: 'Size of the particles',
          valueGet: () => this._opts.size,
          inputChanged: (v: number) => {
            this.setOptions({ size: v });
            this._updateParticles();
          },
          valueChanged: new SyncEvent<number>(),
        },
        sizeVariability: {
          type: "slider" as const,
          label: 'Particle Size Variability',
          min: 0,
          max: 3.0,
          step: 0.1,
          alt: 'Variability of the particle size',
          valueGet: () => this._opts.sizeVariability,
          inputChanged: (v: number) => {
            this.setOptions({ sizeVariability: v });
            this._updateParticles();
          },
          valueChanged: new SyncEvent<number>(),
        },
        damp: {
            type: "slider" as const,
            label: "Damp",
            valueGet: () => this._opts.damp,
            min: 0,
            max: 1,
            step: 0.01,
            inputChanged: (v: number) => this.setOptions({ damp: v }),
            valueSet: (v: number) => this._simulation?.velocityDecay(v),
            valueChanged: new SyncEvent<number>(),
            alt: "Damping factor of the simulation",
        },
        mouseRadius: {
          type: 'slider' as const,
          label: 'Mouse Radius',
          min: 0,
          max: 100,
          step: 1,
          alt: 'Radius of the mouse hover effect',
          valueGet: () => this._opts.mouseRadius,
          inputChanged: (v: number) => this.setOptions({ mouseRadius: v }),
          valueChanged: new SyncEvent<number>(),
        },
        backgroundColor: {
            type: "colorPicker" as const,
            label: "Background Color",
            valueGet: () => this._opts.backgroundColor,
            inputChanged: (v: number) => this.setOptions({ backgroundColor: v }),
            valueSet: (v: number) => document.body.style.backgroundColor = hexToStr(v),
            valueChanged: new SyncEvent<number>(),
            alt: "Background color of the application",
        },
        hoveredParticleColor: {
            type: "colorPicker" as const,
            label: "Hovered Particle Color",
            valueGet: () => this._opts.hoveredParticleColor,
            inputChanged: (v: number) => this.setOptions({ hoveredParticleColor: v }),
            valueSet: (v: number) => this._updateParticles(),
            valueChanged: new SyncEvent<number>(),
            alt: "Color of the hovered particle",
        },
        particleColor: {
          type: 'colorPicker' as const,
          label: 'Particles Color',
          alt: 'Color of the particles',
          valueGet: () => this._opts.particleColor,
          inputChanged: (v: number) => {
            this.setOptions({ particleColor: v });
            this._updateParticles();
          },
          valueChanged: new SyncEvent<number>(),
        },
        spriteSetIdx: {
            type: "select" as const,
            label: "Sprite Set",
            valueGet: () => this._opts.spriteSetIdx,
            inputChanged: (v: number) => this.setOptions({ spriteSetIdx: v }),
            valueSet: async (v: number) => {
                await this._initParticleTextures(this._opts.spriteSets[v].set);
                this._initParticles();
            },
            valueChanged: new SyncEvent<number>(),
            alt: "Sprite set to use for the particles",
            options: this._opts.spriteSets.map((s, i) => [i, s.label]),
        },
        aggregationFieldIdx: {
            type: "select" as const,
            label: "Aggregation Field",
            valueGet: () => this._opts.aggregationFieldIdx,
            inputChanged: (v: number) => this.setOptions({ aggregationFieldIdx: v }),
            valueSet: () => this._updateHubs(),
            valueChanged: new SyncEvent<number>(),
            alt: "Field to use for aggregation",
            options: this._aggregationFields.map((f, i) => [i, f || 'None']),
        },
        zoomSpeed: {
            type: "slider" as const,
            label: "Zoom Speed",
            valueGet: () => this._opts.zoomSpeed,
            min: 0.1,
            max: 1,
            step: 0.1,
            inputChanged: (v: number) => this.setOptions({ zoomSpeed: v }),
            valueChanged: new SyncEvent<number>(),
            alt: "Speed of the zoom",
        },
        zoomInertia: {
            type: "slider" as const,
            label: "Zoom Inertia",
            valueGet: () => this._opts.zoomInertia,
            min: 0.01,
            max: 0.5,
            step: 0.01,
            inputChanged: (v: number) => this.setOptions({ zoomInertia: v }),
            valueChanged: new SyncEvent<number>(),
            alt: "Inertia of the zoom",
        },
    }

    // #endregion

}