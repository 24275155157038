import { makeNoise3D } from "fast-simplex-noise";
import chroma, { Color } from "chroma-js";
import { Force, Map2DFn } from "./force";
import { BlurFilter, Container, Sprite, Texture } from "pixi.js";
import { assert } from "../utils/assert";
import { SyncEvent } from "ts-events";
import { clamp, getClosestLowerNumber } from "../utils/math";

export type SimplexNoiseOpts = {
  enabled?: boolean;
  noiseScale: number;
  noiseSpeed: number;
  noiseFactor: number;
  noiseGridSize: number;
  noiseGridScale: number;
  blurEnabled: boolean;
  blurKernelSize: number;
  blurQuality: number;
  blurStrength: number;
  blurResolution: number;
  showNoise: boolean;
  color1: number;
  color2: number;
};

export class SimplexNoise extends Force<SimplexNoiseOpts> {
  
  private _count: number = 0;
  private _noiseX = makeNoise3D();
  private _noiseY = makeNoise3D();
  private _noiseRects: Sprite[] = [];

  protected _opts: Required<SimplexNoiseOpts> = {
    enabled: false,
    noiseScale: 600,
    noiseSpeed: 150,
    noiseFactor: 0.5,
    noiseGridSize: 10,
    noiseGridScale: 10,
    blurEnabled: true,
    blurKernelSize: 15,
    blurQuality: 2,
    blurStrength: 15,
    blurResolution: 1,
    showNoise: false,
    color1: 0xFF0000,
    color2: 0x0000FF,
  };

  private _color1: Color;
  private _color2: Color;

  constructor(opts?: Partial<SimplexNoiseOpts>) {
    super();
    this._opts = { ...this._opts, ...opts };

    this._color1 = chroma(this._opts.color1);
    this._color2 = chroma(this._opts.color2);
  }

  public resize(width: number, height: number, container: Container): void {
    super.resize(width, height, container);
    this._update();
  }

  private _update(): void {
    assert(this._container, "SimplexNoise: container is not set");
    this._container.visible = this._opts.showNoise;
    this._container.removeChildren();
    this._noiseRects = [];

    for (let x = 0; x < this._width; x += this._opts.noiseGridSize) {
      for (let y = 0; y < this._height; y += this._opts.noiseGridSize) {
        const rect = new Sprite(Texture.WHITE);
        rect.position.set(x/this._opts.noiseGridScale, y/this._opts.noiseGridScale);
        rect.width = this._opts.noiseGridSize/this._opts.noiseGridScale;
        rect.height = this._opts.noiseGridSize/this._opts.noiseGridScale;

        this._container.addChild(rect);
        this._noiseRects.push(rect);
      }
    }

    this._container.scale.set(this._opts.noiseGridScale);
    const blurFilter = new BlurFilter({
      kernelSize: getClosestLowerNumber(this._opts.blurKernelSize, [5, 7, 9, 11, 13, 15]),
      quality: this._opts.blurQuality,
      strength: this._opts.blurStrength,
    });
    blurFilter.repeatEdgePixels = true;
    blurFilter.resolution = this._opts.blurResolution;
    this._container.filters = this._opts.blurEnabled ? [blurFilter] : [];
  }

  public draw(worldToScreen: Map2DFn, zoomFactor: number): void {
    super.draw(worldToScreen, zoomFactor);

    if (!this._opts.showNoise || !this._opts.enabled) return;
    for (const rect of this._noiseRects) {
        const x = rect.x;
        const y = rect.y;
        const nx = this._noiseX(x / this._opts.noiseScale * this._opts.noiseGridScale, y / this._opts.noiseScale * this._opts.noiseGridScale, this._count/this._opts.noiseSpeed);
        const ny = this._noiseY(x / this._opts.noiseScale * this._opts.noiseGridScale, y / this._opts.noiseScale * this._opts.noiseGridScale, this._count/this._opts.noiseSpeed);

        // map to 0-1
        const cx = clamp((nx + 1) / 2, 0, 1);
        const cy = clamp((ny + 1) / 2, 0, 1);

        const ratio = (cx + cy) ? cx / (cx + cy) : 0;

        rect.tint = chroma.mix(this._color1, this._color2, ratio, "oklch").num();
        rect.alpha = (cx + cy) / 2;
    }
  }

  public override apply(alpha: number) {
    if (!this._opts.enabled) return;
    this._count += 1;
    this._nodes.forEach((p) => {
      const nx = this._noiseX(
        p.x / this._opts.noiseScale,
        p.y / this._opts.noiseScale,
        this._count / this._opts.noiseSpeed
      );
      const ny = this._noiseY(
        p.x / this._opts.noiseScale,
        p.y / this._opts.noiseScale,
        this._count / this._opts.noiseSpeed
      );
      p.vx += nx * this._opts.noiseFactor;
      p.vy += ny * this._opts.noiseFactor;
    });
  }

  public config = {
    enabled: {
        type: "checkbox" as const,
        label: "Enabled",
        valueGet: () => this._opts.enabled,
        inputChanged: (v: boolean) => this.setOptions({ enabled: v }),
        valueChanged: new SyncEvent<boolean>, 
    },
    noiseFactor: {
      type: "slider" as const,
      label: 'Noise Factor',
      min: 0,
      max: 0.3,
      step: 0.01,
      alt: 'How much the particles are affected by the noise. Set to 0 to disable noise.',
      valueGet: () => this._opts.noiseFactor,
      inputChanged: (v: number) => this.setOptions({ noiseFactor: v }),
      valueChanged: new SyncEvent<number>(),
    },
    noiseSpeed: {
      type: "slider" as const,
      label: 'Noise Speed',
      min: 1,
      max: 500,
      step: 10,
      alt: 'Speed of the noise',
      valueGet: () => this._opts.noiseSpeed,
      inputChanged: (v: number) => this.setOptions({ noiseSpeed: v }),
      valueChanged: new SyncEvent<number>(),
    },
    noiseScale: {
      type: "slider" as const,
      label: 'Noise Scale',
      min: 100,
      max: 1500,
      step: 10,
      alt: 'Scale of the noise',
      valueGet: () => this._opts.noiseScale,
      inputChanged: (v: number) => this.setOptions({ noiseScale: v }),
      valueChanged: new SyncEvent<number>(),
    },
    noiseGridSize: {
      type: "slider" as const,
      label: 'Noise Grid Size',
      min: 5,
      max: 50,
      step: 1,
      alt: 'Size of the noise grid',
      valueGet: () => this._opts.noiseGridSize,
      inputChanged: (v: number) => this.setOptions({ noiseGridSize: v }),
      valueSet: () => this._update(),
      valueChanged: new SyncEvent<number>(),
    },
    noiseGridScale: {
      type: "slider" as const,
      label: 'Noise Grid Scale',
      min: 1,
      max: 20,
      step: 1,
      alt: 'Scale of the noise grid',
      valueGet: () => this._opts.noiseGridScale,
      inputChanged: (v: number) => this.setOptions({ noiseGridScale: v }),
      valueSet: () => this._update(),
      valueChanged: new SyncEvent<number>(),
    },
    blurEnabled: {
      type: 'checkbox' as const,
      label: 'Blur Enabled',
      valueGet: () => this._opts.blurEnabled,
      inputChanged: (v: boolean) => this.setOptions({ blurEnabled: v }),
      valueSet: () => this._update(),
      valueChanged: new SyncEvent<boolean>(),
      alt: 'Enable or disable the blur'
    },
    blurKernelSize: {
      type: "slider" as const,
      label: 'Blur Kernel Size',
      min: 5,
      max: 15,
      step: 1,
      alt: 'Size of the blur kernel: 5, 7, 9, 11, 13, 15',
      valueGet: () => this._opts.blurKernelSize,
      inputChanged: (v: number) => this.setOptions({ blurKernelSize: getClosestLowerNumber(v, [5, 7, 9, 11, 13, 15]) }),
      valueSet: () => this._update(),
      valueChanged: new SyncEvent<number>(),
    },
    blurQuality: {
      type: "slider" as const,
      label: 'Blur Quality',
      min: 1,
      max: 10,
      step: 1,
      alt: 'Sets the number of passes for blur. More passes means higher quality bluring.',
      valueGet: () => this._opts.blurQuality,
      inputChanged: (v: number) => this.setOptions({ blurQuality: v }),
      valueSet: () => this._update(),
      valueChanged: new SyncEvent<number>(),
    },
    blurStrength: {
      type: "slider" as const,
      label: 'Blur Strength',
      min: 1,
      max: 50,
      step: 1,
      alt: 'Strength of the blur',
      valueGet: () => this._opts.blurStrength,
      inputChanged: (v: number) => this.setOptions({ blurStrength: v }),
      valueSet: () => this._update(),
      valueChanged: new SyncEvent<number>(),
    },
    blurResolution: {
      type: "slider" as const,
      label: 'Blur Resolution',
      min: 0.1,
      max: 2,
      step: 0.1,
      alt: 'Resolution of the blur',
      valueGet: () => this._opts.blurResolution,
      inputChanged: (v: number) => this.setOptions({ blurResolution: v }),
      valueSet: () => this._update(),
      valueChanged: new SyncEvent<number>(),
    },
    showNoise:{
      type: 'checkbox' as const,
      label: 'Show Noise',
      valueGet: () => this._opts.showNoise,
      inputChanged: (v: boolean) => this.setOptions({ showNoise: v }),
      valueSet: () => this._update(),
      valueChanged: new SyncEvent<boolean>(),
      alt: 'Show or hide the noise'
    },
    color1: {
      type: 'colorPicker' as const,
      label: 'Color 1',
      alt: 'Color 1',
      valueGet: () => this._opts.color1,
      inputChanged: (v: number) => this.setOptions({ color1: v }),
      valueSet: () => this._color1 = chroma(this._opts.color1),
      valueChanged: new SyncEvent<number>(),
    },
    color2: {
      type: 'colorPicker' as const,
      label: 'Color 2',
      alt: 'Color 2',
      valueGet: () => this._opts.color2,
      inputChanged: (v: number) => this.setOptions({ color2: v }),
      valueSet: () => this._color2 = chroma(this._opts.color2),      
      valueChanged: new SyncEvent<number>(),
    },
  }
}
