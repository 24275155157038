import { SyncEvent } from "ts-events";
import { Force } from "./force";
import { Particle } from "../models/particle";

export type EdgeForceOpts = {
  enabled?: boolean;
  buffer: number;
  modeIdx: number;
};

export class EdgeForce extends Force<EdgeForceOpts> {

  protected _opts: Required<EdgeForceOpts> = {
    enabled: true,
    buffer: 100,
    modeIdx: 0,
  };

  private _modes = ["wrap", "bounce"];

  constructor(opts?: EdgeForceOpts) {
    super();
    this._opts = { ...this._opts, ...opts };
  }

  public override apply(alpha: number) {
    if (!this._opts.enabled) return;
    if (this._opts.modeIdx === 0) this._wrapParticles();
    else if (this._opts.modeIdx === 1) this._bounceParticles();
  }

  private _wrapParticles() {
    this._nodes.forEach(p => this._wrap(p));
  }

  private _bounceParticles() {
    this._nodes.forEach(p => this._bounce(p));
  }

  private _bounce(p: Particle) {
    if (p.x <  -this._opts.buffer) {
      p.x = -this._opts.buffer;
      p.vx *= -1;
    } else if (p.x > this._width + this._opts.buffer) {
      p.x = this._width + this._opts.buffer;
      p.vx *= -1;
    }
    if (p.y < -this._opts.buffer) {
      p.y = -this._opts.buffer;
      p.vy *= -1;
    } else if (p.y > this._height + this._opts.buffer) {
      p.y = this._height + this._opts.buffer;
      p.vy *= -1;
    }
  }

  private _wrap(p: Particle) {
    if (p.x < -this._opts.buffer) p.x = this._width + this._opts.buffer;
    else if (p.x > this._width + this._opts.buffer) p.x = -this._opts.buffer;
    if (p.y < -this._opts.buffer) p.y = this._height + this._opts.buffer;
    else if (p.y > this._height + this._opts.buffer) p.y = -this._opts.buffer;
  }

  public config = {
    enabled: {
        type: "checkbox" as const,
        label: "Enabled",
        valueGet: () => this._opts.enabled,
        inputChanged: (v: boolean) => this.setOptions({ enabled: v }),
        valueChanged: new SyncEvent<boolean>, 
    },
    buffer: {
      type: "slider" as const,
      label: "buffer",
      valueGet: () => this._opts.buffer,
      min: 0,
      max: 100,
      step: 1,
      inputChanged: (v: number) => this.setOptions({ buffer: v }),
      valueChanged: new SyncEvent<number>(),
      alt: "Buffer around screen",
    },
    modeIdx: {
      type: "select" as const,
      label: "Mode",
      options: this._modes.map((m, i) => [i, m]) as [number, string][],
      valueGet: () => this._opts.modeIdx,
      inputChanged: (v: number) => this.setOptions({ modeIdx: v }),
      valueChanged: new SyncEvent<number>(),
    },
  }
}
