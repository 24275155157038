import { SyncEvent } from "ts-events";

export type ConfigurationItem = CheckboxConfiguration | ColorConfiguration | SliderConfiguration | TextConfiguration | SelectConfiguration;

export type BaseConfiguration<T extends string | boolean | number> = {
    label: string,
    alt?: string,
    inputChanged: (v: T) => void,
    valueChanged: SyncEvent<T>,
    valueSet?: (v: T) => void,
    valueGet: () => T,
};

export type CheckboxConfiguration = BaseConfiguration<boolean> & { type: "checkbox" };
export type ColorConfiguration = BaseConfiguration<number> & { type: "colorPicker" };
export type TextConfiguration = BaseConfiguration<string> & { type: "text" };
export type SelectConfiguration = BaseConfiguration<number> & { type: "select", options: [number, string][] };
export type SliderConfiguration = BaseConfiguration<number> & {
    type: "slider",
    min: number,
    max: number,
    step: number,
    eventType?: "input" | "change",
};

export type Configuration<T extends Options> = Partial<{
    [K in keyof T]-?: T[K] extends boolean|undefined ? CheckboxConfiguration :
                    T[K] extends string|undefined ? TextConfiguration :
                    T[K] extends number|undefined ? (ColorConfiguration | SliderConfiguration | SelectConfiguration) :
                    never;
}>;

export type Options = Record<string, boolean | string | number | Array<any>>;

export abstract class Configurable<TOpts extends Options> {
    protected _opts: Required<TOpts> = {} as Required<TOpts>;

    public abstract config: Configuration<TOpts>;

    public setOptions(opts: Partial<TOpts>): void {
        this._opts = { ...this._opts, ...opts };
        Object.entries(opts).forEach(([k, v]: [string, boolean|number|string]) => {
            let config = this.config[k as keyof TOpts] as any;
            if (!config) return;
            config.valueChanged.post(v);
            config.valueSet?.(v);
        })
    }

}