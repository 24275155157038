const toRadians = (degrees: number): number => degrees * (Math.PI / 180);
const toDegrees = (radians: number): number => radians * (180 / Math.PI);

export function getPointAtDistance(lat1: number, lon1: number, d: number, bearing: number, R: number = 6371): { lat: number, lon: number } {
    /**
     * lat1: initial latitude, in degrees
     * lon1: initial longitude, in degrees
     * d: target distance from initial
     * bearing: (true) heading in degrees
     * R: optional radius of sphere, defaults to mean radius of earth
     *
     * Returns new lat/lon coordinate {d}km from initial, in degrees
     */

    lat1 = toRadians(lat1);
    lon1 = toRadians(lon1);
    const a = toRadians(bearing);

    const lat2 = Math.asin(Math.sin(lat1) * Math.cos(d / R) + Math.cos(lat1) * Math.sin(d / R) * Math.cos(a));
    const lon2 = lon1 + Math.atan2(
        Math.sin(a) * Math.sin(d / R) * Math.cos(lat1),
        Math.cos(d / R) - Math.sin(lat1) * Math.sin(lat2)
    );

    return { lat: toDegrees(lat2), lon: toDegrees(lon2) };
}

export function distanceInKm(lng1: number, lat1: number, lng2: number, lat2: number, R: number = 6371): number {
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lng2 - lng1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
}